import { createSlice } from '@reduxjs/toolkit';

export const getModuleState = (state) => state.chat;

export const selectors = {
  getUnreadMessageCount(state) {
    const { unreadMessageCount } = getModuleState(state);

    return unreadMessageCount;
  },
  getChatDraftText(state, chatId) {
    if (!chatId) {
      return '';
    }

    const { draftText } = getModuleState(state).chats[chatId] || {};
    return draftText;
  },
  getExistingChats(state) {
    const { existingChats } = getModuleState(state);

    return existingChats;
  },
};

const slice = createSlice({
  name: 'chat',
  initialState: {
    existingChats: [],
    chats: {},
    unreadMessageCount: 0,
  },
  reducers: {
    setUnreadMessageCount(state, { payload }) {
      state.unreadMessageCount = payload;
    },
    setDraftText(state, { payload }) {
      const { chatId, draftText } = payload;
      if (!state.chats[chatId]) {
        // Create the chat object if it doesn't exist
        state.chats[chatId] = {};
      }
      state.chats[chatId].draftText = draftText;
    },
    setExistingChats(state, { payload }) {
      state.existingChats = JSON.parse(JSON.stringify([...payload[0]]));
    },
    updateExistingChats(state, { payload }) {
      const payloadClone = JSON.parse(JSON.stringify(payload));
      let index = state.existingChats.findIndex(
        (chat) => chat.url === payloadClone.url
      );
      if (index >= 0) {
        state.existingChats[index] = payloadClone;
      } else {
        state.existingChats = [...state.existingChats, payloadClone];
      }
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
